<template>
  <v-container id="container" fluid>
    <v-row align="center" justify="center" class="mt-10" style="height: 250px">
      <div class="text-center">
        <div class="text-center">
          <v-img
            id="imgforbidden"
            min-width="300"
            max-width="650"
            class="ml-4"
            :src="forbidden"
          ></v-img>
          <h3>Ops! Acho que você está desabilitado..</h3>
          <p>{{ message }}</p>
        </div>
      </div>
    </v-row>
    <v-row id="btn" align="end" justify="center" class="mt-10">
      <v-btn elevation="5" @click="$router.go(-1)" color="accent">
        Voltar para a página anterior
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageError",
  data() {
    return {
      forbidden: require("@/assets/images/403forbidden.png"),
      code: 403,
      message:
        "Verifique seu faturamento e entre em contato com o nosso suporte!",
    };
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Press+Start+2P&display=swap");
#btn {
  height: 50% !important;
}

h3,
p {
  font-family: "Open Sans", sans-serif;
  color: rgb(49, 31, 80);
  margin: 30px;
}

#container {
  background: rgb(49, 31, 80);
  background: linear-gradient(
    106deg,
    rgba(49, 31, 80, 0.6628852224483543) 0%,
    rgba(255, 255, 255, 1) 48%
  );
  height: 100%;
  max-height: max-content;
}

#imgforbidden {
  transition: all 2s;
  margin: auto !important;
}

@media (max-width: 650px) {
  #imgforbidden {
    width: 480px !important;
  }
  h3 {
    font-size: larger !important;
  }
}

@media (max-width: 450px) {
  #imgforbidden {
    width: 330px !important;
  }
  h3 {
    font-size: medium !important;
  }
}
</style>
